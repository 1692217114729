.students-pro {
    text-align: center;
}
.report-filter {
    position: absolute;
    right: 0;
}
.report-filter img {
    width: 40px;
    cursor: pointer;
}
.academic-box{
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 30px;
}
.academic-box .academic-header {
    display: flex;
}
.academic-box .academic-header .academic-profile{
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 15px;
}
.academic-box .academic-header .academic-profile img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.academic-box .academic-header .academic-title{
    flex: 1;
}
.academic-box .academic-header .academic-title h3{
    margin-bottom: 0;
}
.academic-box .academic-header{
    padding-bottom: 15px;
    position: relative;
}
.academic-box .academic-body{
    background: #5f727f17;
    border-radius: 15px;
    padding: 15px;
    display: block;
}
.academic-box .academic-body label.posted{
    font-size: 18px;
    font-weight:700;
}
.academic-box .academic-body label.posted span{
    color: #42AFEF;
}
.academic-box .academic-body label.submission-date{
    font-size: 18px;
    font-weight:700;
}
.academic-box .academic-body label.submission-date span{
    color: #000;
}
.academic-box .academic-body h3{
    margin-bottom: 5px;
    font-size: 24px;
}
.academic-box .academic-body .pdf-view{
    display: flex;
    flex-wrap:wrap;
}
.academic-box .academic-body .pdf-view a{
    background: #5f727f1c;
    padding: 5px 10px;
    border-radius: 5px;
    color: #000;
    font-weight: 700;
}
.academic-box .academic-body .pdf-view a img{
    width: 21px;
}
.more a.circle-nav {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #5F727F;
    display: inline-block;
    text-align: center;
    color: #5F727F;
    line-height: 24px;
}
.comment-right .more{
    top: 0;
}
.my-school .comment-right .more-option {
    min-width: 120px;
}
.comment-right .more-option {
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1), opacity 0.3s ease-in-out;
    transform: translateY(-4rem);
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
    background: #fff;
    min-width: 100px;
    right: 0;
    padding: 5px;
    border-radius: 5px;
    visibility: hidden;
    display: block;
}
.comment-right .more .more-option a{
    text-align: left;
    color: #000;
    display: block;
}
.comment-right .more:hover .more-option {
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    visibility: visible;
    top: 30px;
}
.more-option::before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #999999;
    border-top: 0;
    right: 10px;
    left: auto;
}
.more-option::after {
    content: '';
    display: block;
    position: absolute;
    left: auto;
    top: -10px;
    bottom: 100%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-top: 0px solid #fff;
    /* border-bottom-color: #ffffff; */
}
.students-pro img {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
}
.report-form{
    text-align: left;
}
.report-form .custom-control {
    padding: 12px;
    border-radius: 30px;
    background: #EFF1F3;
    margin-bottom: 15px;
}
.report-form .custom-control .custom-control-label {
    margin-left: 30px;
    margin-bottom: 0;
}
.report-form .cybersmart-btn{
    max-width: 100%;
}
.modal-body.post-modal,.modal-content.post-modal{
    height: auto;
    min-height: auto;
}

.post-title img {
    width: 80px;
    margin-bottom: 10px;
}
.modal-header img {
    width: 100px;
    margin: auto;
}

.class-form .form-control{
    background: #fff;
    border-radius: 30px;
    padding: 15px 20px;
    min-height: 48px;
    border: 0;
}
.class-form .form-group{
    position: relative;
}
.class-form .form-group img.form-icon{
    width: 36px;
    position: absolute;
    right: 10px;
    bottom: 6px;
}
.upload-view-box {
    background: #fff;
    border-radius: 30px;
    padding: 15px 20px;
    text-align: left;
}
.upload-view {
    display: flex;
    flex-wrap: wrap;
}
.upload-view a {
    background: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    color: #000;
    font-weight: 700;
}
.upload-view a img {
    width: 21px;
}
label.upload-image {
    display: block;
}
label.upload-image img.addimg {
    width: 30px;
    float: right;
}
.upload-image input[type="file"] {
    display: none;
}
.my-school .comment-right {
    position: absolute;
    right: 0;
}

label.instruction-btn {
    background: #5f727f1a;
    border-radius: 30px;
    padding: 5px 15px;
    cursor: pointer;
    margin-right: 45px;
    margin-top: -10px;
}
.more a.circle-nav {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #5F727F;
    display: inline-block;
    text-align: center;
    color: #5F727F;
    line-height: 24px;
}
.comment-right .more{
    top: 0;
}
.my-school .comment-right .more-option {
    min-width: 160px;
}
.comment-right .more-option {
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1), opacity 0.3s ease-in-out;
    transform: translateY(-4rem);
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
    background: #fff;
    min-width: 100px;
    right: 0;
    padding: 5px;
    border-radius: 5px;
    visibility: hidden;
    display: block;
}
.comment-right .more .more-option a{
    text-align: left;
    color: #000;
    display: block;
}
.comment-right .more:hover .more-option {
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    visibility: visible;
    top: 30px;
}
.more-option::before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #999999;
    border-top: 0;
    right: 10px;
    left: auto;
}
.more-option::after {
    content: '';
    display: block;
    position: absolute;
    left: auto;
    top: -10px;
    bottom: 100%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-top: 0px solid #fff;
    /* border-bottom-color: #ffffff; */
}
.circle-title {
    font-size: 5px;
    vertical-align: middle;
    padding: 0px 2px;
}
.modal-body.post-modal,.modal-content.post-modal{
    height: auto;
    min-height: auto;
}
button.cybersmart-btn-m {
    background-color: #5b2fa2;
    box-shadow: 0px 4px 0px #3a1b6d;
    border: 0;
    font-size: 14px;
    padding: 10px 15px;
    display: inline-block;
    outline: 0;
    font-family: BubblegumSans;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    cursor: pointer;
    border-radius: 22px;
    background-color: #5b2fa2;
    text-align: center;
    max-width: 170px;
    text-decoration: none;
}
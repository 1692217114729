.mark-remark-box {
    background: #8DC63F;
    padding: 20px;
    border-radius: 15px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.remark-list {
    padding: 15px;
}

.marks-form {
    padding: 15px;
}

.marks-form .form-group {
    position: relative;
}

.marks-form .form-group .form-control {
    height: 48px;
    border-radius: 48px;
    border: 0;
}

.marks-form .form-group label {
    background: #5f727f26;
    padding: 0 25px;
    height: 48px;
    line-height: 48px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.custom-control-label {
    margin-bottom: 0.5rem;
    padding-left: 5px;
}

.custom-control-label::before {
    top: 0.1rem;
    left: -1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #5F727F;
    border-radius: 50% !important;
}

.custom-checkbox .custom-control-label::after {
    top: -0.1rem;
    left: -1.75rem;
    width: 2rem;
    height: 2rem;
}

.custom-radio .custom-control-label::after {
    top: -0.15rem;
    left: -1.75rem;
    width: 2rem;
    height: 2rem;
}
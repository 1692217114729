.class-form .form-control{
    background: #5f727f12;
    border-radius: 30px;
    padding: 15px 20px;
    min-height: 48px;
    border: 0;
    }
    .class-form .form-group{
    position: relative;
    }
    .class-form .form-group img.form-icon{
    width: 36px;
    position: absolute;
    right: 10px;
    bottom: 6px;
    }
    .upload-view-box {
    background: #5f727f12;
    border-radius: 30px;
    padding: 15px 20px;
    }
    .upload-view {
    display: flex;
    flex-wrap: wrap;
    }
    .upload-view a {
    background: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    color: #000;
    font-weight: 700;
    }
    .upload-view a img {
    width: 21px;
    }
    label.upload-image {
    display: block;
    }
    label.upload-image img.addimg {
    width: 30px;
    float: right;
    }
    .upload-image input[type="file"] {
    display: none;
    }
